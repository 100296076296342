import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Button, Col, Card } from 'react-bootstrap';
import { FaQuoteLeft } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import './index.css';


class Reviews extends React.Component{
    render(){
        return(
            <Container fluid  style={{display: "inline-block", height: "100%", backgroundColor: "#F4F4F4"}}>
            <Row className='justify-content-center'>
            <Col>
            <Fade>

            <Row>
              <Col> 
            <div className="vl"/>
            <h5>Proven results, <div style={{display: "inline", color: "#B01318"}}>every time</div>.</h5>
            </Col>
            </Row>
            
            <Row>
              <Col>
            <h2 className="abtOurServices" style={{fontWeight: "bold", lineHeight: "3"}}>Core Capabilities:</h2>
            </Col>
            </Row>
            </Fade>

            <Fade>
            <Row >

            <Col style={{display: "flex", justifyContent: "center"}}>
            <Card style={{ width: '18rem', height: "400px", marginBottom: "50px", minWidth: "250px" }}>
        <Card.Body>
        <Card.Title><strong>Cyberspace & Cybersecurity Support</strong></Card.Title>
        <hr style={{width: "200px", backgroundColor: "black", height: "1px"}}/>
        <Card.Text style={{lineHeight: "2"}}>
        <span style={{color:"#B01318"}}>•</span> CEMA Support<br></br>
        <span style={{color:"#B01318"}}>•</span> Computer Programming<br></br>
        <span style={{color:"#B01318"}}>•</span> Data Security Analysis<br></br>
        <span style={{color:"#B01318"}}>•</span> Mission Assurance Support<br></br>
        <span style={{color:"#B01318"}}>•</span> Risk Management Framework (RMF)<br></br>

        </Card.Text>
        </Card.Body>
        </Card>
        </Col>
        
        <Col style={{display: "flex", justifyContent: "center"}}>
        <Card style={{ width: '18rem', height: "400px", marginLeft: "auto", marginRight: 'auto', marginBottom: "50px", minWidth: "250px" }}>
        <Card.Body>
        <Card.Title><strong>Training & Development</strong></Card.Title>
        <hr style={{width: "200px", backgroundColor: "black", height: "1px"}}/>
        <Card.Text style={{lineHeight:"2"}}>
        <span style={{color:"#B01318"}}>•</span>	Training Development and Integration<br></br>
        <span style={{color:"#B01318"}}>•</span>	SOF Training and Exercise Support<br></br>
        <span style={{color:"#B01318"}}>•</span>	Medical Simulation Support<br></br>
        <span style={{color:"#B01318"}}>•</span>	ISR Training Support<br></br>
        <span style={{color:"#B01318"}}>•</span>	Role Player Support<br></br>

        </Card.Text>
        </Card.Body>
        </Card>
        </Col>
        
        <Col style={{display: "flex", justifyContent: "center"}}>
        <Card style={{ width: '18rem', height: "400px", marginLeft: "auto", marginRight: 'auto', marginBottom: "50px", minWidth: "250px" }}>
        <Card.Body>
        <Card.Title><strong>Advanced IT Administration</strong></Card.Title>
        <hr style={{width: "200px", backgroundColor: "black", height: "1px"}}/>
        <Card.Text style={{lineHeight: "2"}}>
        <span style={{color:"#B01318"}}>•</span>  Cloud Computing, Virtualization and System Administration<br></br>
        <span style={{color:"#B01318"}}>•</span>	Computer Programming, Database Development and Application Development<br></br>
        <span style={{color:"#B01318"}}>•</span>	Modeling & Simulation (JLVC)<br></br>

        </Card.Text>
        </Card.Body>
        </Card>
        </Col>
        </Row>
    </Fade>
    </Col>
            </Row>    
               
                </Container>
     
        )
    };

}
export {Reviews};