import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import { Social } from './social';
import './index.css'

let Fb = require("./f_logo_RGB-White_58.png");
let Insta = require("./Instagram_Glyph_White.png");
let Twit = require("./twitter_icon.png");
let Lin = require("./In-White-59px-R.png");


class Footer extends React.Component{

    render(){
        return(
            <div style={{height: "100%", backgroundColor: "black", marginBottom: "10px"}}>
            <div style={{alignItems: "center", marginBottom: "100px"}}>
            <div >
            <a href="https://www.facebook.com/VoidKnightLLC"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "10px", marginTop: "100px"}} src={Fb}/></a>
            <a href="https://www.instagram.com/voidknightllc"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "10px", marginTop: "100px"}} src={Insta}/></a>
            <a href="https://twitter.com/VoidKnightLLC"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "10px", marginTop: "100px"}} src={Twit}/></a>
            <a href="https://www.linkedin.com/company/void-knight-llc"><img className="socialLinks" style={{width: "25px", height: "25px", marginTop: "100px"}} src={Lin}/></a>
            </div>
            </div>
            <div style={{color: "white"}}>
            © {new Date().getFullYear()} <a href="/" style={{textDecoration: "none", color: "white"}}>Void Knight LLC</a>. All Rights Reserved. 
            </div>
            </div>
     
        )
    };

}
export {Footer};