import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Button } from 'react-bootstrap';
import './index.css';
import { BsArrowRight } from "react-icons/bs";
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Pulse from 'react-reveal/Pulse';




let systemAdmin = require('./system_administration.png');
let capabilitycard = require("./capcard.pdf");


class Solutions extends React.Component{
    
    openPDF = () => {
        window.open(capabilitycard);
      }
    
    render(){


        return(
            
            <div className="greatSolutions" style={{height: "100%"}}>
            <Row className="solutionWrapper mx-auto" style={{display: "inline-flex"}}>
            <Col className="solutionsCol" style={{marginLeft: "90px"}}>
            <Row>

            <h5 className="ourExp" style={{color: "black", borderLeft: "2px solid #B01318", width: "185px", paddingTop: "10px", paddingBottom: "10px", marginTop: "50px", fontWeight: "bold"}}>OUR EXPERTISE</h5>
            </Row>
            <Row>
            <Fade >
            <h1 className="syct" style={{color: "#B01318", marginTop: "50px", marginBottom: "50px", fontWeight: "bold", width: "100%", display: "inline-flex", justifyContent: "left" }}>Solutions You Can Trust</h1>
            </Fade>
            <ul style={{display: "flex", flexDirection: "column"}}>

            <Fade>

            <li className="solHeading" style={{display: "inline-flex" }}><div className="dot solDot" style={{backgroundColor: "transparent", border: "2px solid #B01318", marginBottom: "50px"}}/>Nuanced approaches to complex problems</li>
            </Fade>
            <Fade>
            
            <li className="solHeading" style={{display: "inline-flex" }}><div className="dot solDot" style={{backgroundColor: "transparent", border: "2px solid #B01318", marginBottom: "50px"}}/>Direct evaluation of capability and necessity</li>
            
            </Fade>
            <Fade>
            
            <li className="solHeading" style={{display: "inline-flex" }}><div className="dot solDot" style={{backgroundColor: "transparent", border: "2px solid #B01318", marginBottom: "25px"}}/>Implementation of the latest tools and techniques</li>
            
            </Fade>
            </ul>
            </Row>


            <Fade>

            <Button onClick={this.openPDF} className="learnMore" style={{border: "none", display: "block", marginLeft: "125px", color: "black", backgroundColor: "transparent", marginTop: "25px", fontWeight: "bold"}}>Learn More<BsArrowRight size="1.5em" style={{width: "40px"}}/></Button>
            </Fade>
             </Col>

             
            <Fade>
            <img className="solutionsImage" style={{width: "100%", height: "485px", width: "633px", float: "right", marginRight: "80px", marginBottom: "50px", marginLeft: "80px"}} src={systemAdmin}/>
            </Fade>
            
            </Row>
            </div>
        )
    };

}
export {Solutions};