import React from 'react';
import { Row } from 'react-bootstrap';
import './index.css'

let Fb = require("./f_logo_RGB-White_58.png");
let Insta = require("./Instagram_Glyph_White.png");
let Twit = require("./twitter_icon.png");
let Lin = require("./In-White-59px-R.png");



class Social extends React.Component{
    render(){
        return(
        <div >
            <div>
            <a href="https://www.facebook.com/VoidKnightLLC"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "20px"}} src={Fb}/></a>
            <a href="https://www.instagram.com/voidknightllc"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "20px"}} src={Insta}/></a>
            <a href="https://twitter.com/VoidKnightLLC"><img className="socialLinks" style={{width: "25px", height: "25px", marginRight: "20px"}} src={Twit}/></a>
            <a href="https://www.linkedin.com/company/void-knight-llc"><img className="socialLinks" style={{width: "25px", height: "25px"}} src={Lin}/></a>
            </div>
        </div>
        )
    };

}
export {Social};