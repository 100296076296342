import React from 'react';
import './App.css'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from './footer';
import { Row, Col, Button, Container, Card } from 'react-bootstrap';
import './font.css';
import './App.css'
import { Story } from './ourStory';
import { Navigation } from './NavBar';
import { BsArrowRight } from "react-icons/bs";
import Fade from 'react-reveal/Fade';
import { Spin as Hamburger } from 'hamburger-react';



let knightHands = require('./knightHands.jpeg');
let knight = require('./knight.svg');
let mainLogo = require('./mainLogo.png');
let parisWolf = require('./avatar.svg');
let clintonMiller = require('./avatar.svg');
let capabilitycard = require("./capcard.pdf");



class About extends React.Component{

  state = {
    menuClicked: false
  }

  openPDF = () => {
    window.open(capabilitycard);
  }

  handleClick = () => {
    if (this.state.menuClicked === false){
      document.documentElement.style.overflowY="hidden";
  } else {
      document.documentElement.style.overflowY="auto";
  } 
  this.setState({menuClicked: !this.state.menuClicked
  })
}

  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    shrinkOn = 100,
    headerEl = document.getElementById('logo');

    if (distanceY > shrinkOn) {
        headerEl.classList.add("logoShrink");
        headerEl.classList.remove("logoShrank");
    }

        else {
            headerEl.classList.remove("logoShrink");
            headerEl.classList.add("logoShrank");

        }
    }
    render(){
        return(
            <div className="font-link">
              
                <div style={{position: "fixed", width: "100%", backgroundColor: "white", zIndex: "1000"}}>
            <Row>
            
            <div className="logoWrapper" style={{ width: "100%", position: "relative", display: "inline-flex" }}>
            <Hamburger color='black' size={22} direction={"left"} onToggle={this.handleClick}/>

            <a href="/"><img id='logo' src={mainLogo} style={{height: "75px", width: "75px", marginTop: "10px", marginBottom: "10px"}}/></a>
            
            <Col>
            </Col>
            <Col className="align-items-center" style={{display: "inline-flex", justifyContent: "right"}}>
            <Button onClick={this.openPDF} className="capCard" style={{borderColor: "black", marginRight: "81px", height: "50px", backgroundColor: "white", color: "#B01318"}}>Capability Card</Button>
            </Col>
            </div>
            
            </Row>
            
        <Navigation data={this.state}/>
        </div>

                <Row>
                  <Col>
                <Row className="justify-content-center" style={{width: "100vw"}}>
                    <Col>
                <Container disableGutters fluid style={{ backgroundImage: `url(${knightHands})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "black", backgroundPosition: "50% 50%", width: "100vw", height: "495px", marginTop: "100px" }} />
                </Col>
                </Row>
            <Row style={{height: "50px"}}>
                <Col style={{width:"100vw"}}>
                <Story className="ourStory" />
            </Col>
            </Row>
            <Fade>
            <Row className="knightWrapper" style={{width: "100vw"}}>
                <Col className="knightCol" >                
            <img className="knight" src={knight} style={{height: "35rem", width: "100%"}}/>
            </Col>
            <Col className="storyCol" style={{marginTop: "80px", width: "100vw", maxWidth: "920px"}}>
            <h1 className="storyHeader" style={{float: "right", marginRight: "50px", color: "#B01318", fontWeight: "bold", marginBottom: "25px"}}>VOID KNIGHT</h1>
            <p className="storyP" style={{maxWidth: "550px", float: "right", marginRight: "50px", textAlign: "right", lineHeight: "3", marginBottom: "25px"}}>Void Knight, LLC is a government contracting firm founded by two close friends with shared ideals and values. We provide technology services and training to government customers across the globe. In order to provide the best service for government customers we invest in talented and experienced employees, applying proven project management techniques and innovative technologies while treating our clients, customers, and employees with the utmost loyalty, vigilance, honesty.</p>
            </Col>
            </Row>
            </Fade>
            </Col>
            </Row>


            <Row style={{height: "100%", width: "100vw", marginLeft: "0px", display: "flex"}}>
          
            <Col style={{minHeight: "307px", height: "100%", backgroundColor: "#F4F4F4", opacity: "100%"}}>
            <h4 className='abtParaHeader' style={{fontWeight: "bold", marginTop: "50px", marginLeft: "50px", textAlign: "left"}}><span style={{borderBottom: "solid 1px black" }}>Our Mission </span><BsArrowRight size="1.5em"/></h4>
              <Row>
                <Col>
                
                <Fade>
                  
                <p className='aboutPara' style={{minWidth: "336px" ,marginTop: "25px", lineHeight: "3", textAlign: "left", marginLeft:"50px", textAlign: "left", marginRight: "50px", marginBottom: "50px"}}>To become the number one company to work for.</p>
                </Fade>
                </Col>
              </Row>             

            </Col>

            <Col style={{minHeight: "307px", height: "100%", backgroundColor: "#B01318", opacity: "100%", color: "white"}}>            
            <h5 className='abtParaHeader' style={{fontWeight: "bold", marginTop: "50px", marginLeft: "50px", textAlign: "left"}}><span style={{borderBottom: "solid 1px white" }}>Our Vision </span><BsArrowRight size="1.5em"/></h5>
            <Row>
                <Col>
                <Fade>
                <p className='aboutPara' style={{minWidth: "336px", marginTop: "25px", lineHeight: "3", textAlign: "left", marginLeft:"50px", textAlign: "left", marginRight: "50px", marginBottom: "50px"}}>Create bonds of loyalty by investing in and supporting our employees, clients, and customers.</p>
                </Fade>
                </Col>
            </Row>
            </Col>

            <Col style={{minHeight: "307px", height: "100%", backgroundColor: "#F4F4F4", opacity: "100%"}}>
            
            <h5 className='abtParaHeader' style={{fontWeight: "bold", marginTop: "50px", marginLeft: "50px", textAlign: "left"}}><span style={{borderBottom: "solid 1px black" }}>Our Values </span><BsArrowRight size="1.5em"/></h5>

            <Row>
                <Col>
                <Fade>
                <p className='aboutPara' style={{minWidth: "336px", marginTop: "25px", lineHeight: "3", textAlign: "left", marginLeft:"50px", textAlign: "left", marginRight: "50px", marginBottom: "50px"}}>Void Knight was founded on three core values Loyalty, Vigilance, and Honesty.</p>
                </Fade>
                </Col>
            </Row>
            </Col>
            

            </Row>
            
            <Row>
            <Col>
            <div className="vl" style={{borderColor: "#B01318"}}/>
            </Col>
            </Row>
            <Row>
            <Col>
            <h5>WHO WE ARE</h5>            
            </Col>
            </Row>
            <Row>
            <Col>
            <h1 style={{color: "#B01318", fontWeight: "bold", lineHeight: "1.5"}}>MEET OUR TEAM</h1>            
            </Col>
            </Row>
            <Row>
            <Col className='d-flex justify-content-center' style={{textAlign: "center"}}>
            <Fade>
            <p className='ourTeamPara' style={{width: "550px", lineHeight: "2"}}>Void Knight’s founding members are bound together by their commitment to building a better future.</p>            
            </Fade>
            </Col>
            </Row>

            <Row className="justify-content-center" style={{overflow: "hidden"}}>
            <Row style={{marginTop: "50px"}}>
                <Col className="bioCol" style={{display: "inline-flex"}}>
                <Card className="portrait" style={{marginLeft: "75px", width: '18rem', filter: `drop-shadow(.1rem .1rem 0.5rem #f4f4f4`, marginBottom: "25px", minWidth: "286px"}}>
      <Card.Img variant="top" height={'403px'} src={parisWolf} />
      <Card.Body>
        <h4 style={{fontWeight: "bolder"}}>Paris Wolf</h4>
        <Card.Text style={{color: "#B01318"}}>
          Founder & CEO
        </Card.Text>
      </Card.Body>
    </Card>
    <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
                <Fade>
                <p style={{maxWidth: "750px", float: "left", textAlign: "left", lineHeight: "3"}}>Paris Wolf is the owner and Chief Executive Officer of Void Knight LLC with eight years of experience in network security and project management. While enlisted in the United States Army, Paris Wolf served at NETCOM Headquarters and participated on missions such as the DEFENDER PACIFIC 21. Paris received a Bachelors in Network Operations and Security at Western Governors university in 2021 and is a major supporter of self-education. In addition to experience in Satellite Operations and Serverless Cloud Engineering, Paris is a Certified Advanced Security Practitioner (CASP) and has successfully trained dozens of IT professionals to meet and exceed DoD standards.</p>
                </Fade>
                </div>

                </Col>
            </Row>

            <Row style={{marginTop: "50px", marginBottom: "25px"}}>
                <Col className="bioCol" style={{display: "inline-flex", flexDirection: "row-reverse"}}>
                <Card className="portrait clintonCard" style={{marginRight: "75px", width: '18rem', filter: `drop-shadow(.1rem .1rem 0.5rem #f4f4f4`, marginBottom: "25px", minWidth: "286px"}}>
      <Card.Img variant="top" height={'403px'} src={clintonMiller} />
      <Card.Body>
        <h4 style={{fontWeight: "bolder"}}>Clinton Miller</h4>
        <Card.Text style={{color: "#B01318"}}>
          Co-Founder & COO
        </Card.Text>
      </Card.Body>
    </Card>
    <div style={{paddingLeft: "50px", paddingRight: "50px"}}>
                <Fade>
                <p style={{maxWidth: "750px", float: "left", textAlign: "left", lineHeight: "3"}}>Clinton Miller is a native of Las Vegas, Nevada. He began his career in cyber security two years ago as a help desk technician. After obtaining several certifications, including CASP+, he became a Red Hat Systems Admin. The company draws on his experience in operating and maintaining over 100 physical and virtual servers in many of its evaluations of contracts and when constructing training material. Overseeing the day-to-day operations of the company, he uses his expertise to incorporate best business practices, Organizational Design, and corporate business development strategy. To compensate for any weaknesses, the owners have divided the company into areas that each have specific expertise and experience.</p>
                </Fade>
                </div>
                </Col>
            </Row>

           
            
            </Row>
            <Row >
              <Col style={{backgroundColor: "black"}}>
              <Footer />

            </Col>
            </Row>
            </div>
        )
    };

}
export {About};