import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css';
import { BsChevronRight } from "react-icons/bs";
import { Fade } from 'react-reveal';

let suit = require('./suit.png');


class ChooseUS extends React.Component{
    render(){
        return(
            
            <Container fluid className='chooseUs' style={{ backgroundImage: `url(${suit})`, backgroundSize: "cover", height: "100%" }} >
            <Fade>
            <Row>
            <Col className="emptyCol">
            
            </Col>
            <Col className="chooseUsText" style={{color: "#B01318", float: "left", marginLeft: "100px"}}>

            <Row style={{justifyContent: "left", marginTop: "50px"}}>
                <div>
            <BsChevronRight size={"1.25em"} style={{fontSize: "18px"}}/><div style={{color: "white", display: "inline-flex", marginBottom: "25px", marginLeft: "5px", fontWeight: "bold"}}> The Void Knight Way</div>
            </div>
            </Row>
            
            <Row>
            <Col>
            <h1 style={{color: "white", fontWeight: "bold", float: "left", lineHeight: "2"}}>Why Us?</h1>
            </Col>
            </Row>
            
            <Row>
            <p className="chooseUsP" style={{color: "white", marginLeft: "5px", fontSize: "22px", textAlign: "left", paddingRight: "80px"}}>
            Founded by Veterans, Void Knight excels at assisting in the translation of skills obtained in the Armed Forces into skills applicable in the commercial world. We utilize proven project management techniques and innovative technologies to ensure our employees are ever-ready to fulfill the needs of our customers and clients.
            </p>
            </Row>
            <ul style={{display: "flex", flexDirection: "column", color: "white", fontWeight: "bold", marginBottom: "25px", marginTop: "25px"}}>
            <li style={{display: "inline-flex", marginRight: "10px!important"}}><div className="dot" style={{minHeight: "10px", minWidth: "10px", marginBottom: "35px", marginTop: "7px"}}/>Veteran Owned and Operated</li>
            <li style={{display: "inline-flex", marginRight: "10px!important"}}><div className="dot" style={{minHeight: "10px", minWidth: "10px", marginBottom: "35px", marginTop: "7px"}}/>Best support among all vendors</li>
            <li style={{display: "inline-flex", marginRight: "10px!important"}}><div className="dot" style={{minHeight: "10px", minWidth: "10px", marginBottom: "35px", marginTop: "7px"}}/>Price competitive</li>
            </ul>
            </Col>

            </Row>
            </Fade>
            </Container>
        )
    };

}
export {ChooseUS};