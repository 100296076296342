import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Navigation } from './NavBar';
import './index.css';

let mainLogo = require('./mainLogo.png');
let knight = require('./knight.svg');

class Story extends React.Component{
    render(){
        return(
            <div style={{float: "right"}}>
    
    
    <h4 className="ourStory" style={{marginRight: "80px",color: "black", borderRight: "2px solid #B01318", width: "185px", paddingTop: "10px", paddingBottom: "10px", fontWeight: "bold", marginTop: "25px"}}>OUR STORY</h4>

    </div>
        )
    };

}
export {Story};