import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Button } from 'react-bootstrap';
import "./index.css";


class Contract extends React.Component{
    render(){
        return(
            <Container fluid  style={{display: "inline-block", height: "100%", backgroundColor: "#B01318"}}>
            <Row >
                    <hr className = "contractLine" style={{width: "450px", backgroundColor: "white", height: "1px", marginTop: "75px"}}/>
            </Row>
            <Row className='justify-content-center'>
                    <h1 className="contractHeader" style={{color: "white", lineHeight: "3", fontWeight: "bold"}}>Let's Discuss A Contract</h1>
            </Row>
            <Row className='justify-content-center' style={{marginBottom: "75px"}} >
            <Button href={"mailto: paris.a.wolf@voidknightllc.com"} style={{backgroundColor: "white", color: "#B01318"}}>Contact Us</Button>
            </Row>
                
               
                </Container>
     
        )
    };

}
export {Contract};