import React, {Component} from 'react';
import './App.css';
import { HomePage } from './home'; 
import { About } from './aboutUs';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


class App extends Component {

  render(){
    return (
      <Router basename='/'>
      <div className="App">

        
        <Routes>
        <Route index path="/" exact element={ <HomePage/> } />
        <Route path="/about" element={ <About/> } />

      </Routes>

      </div>
      </Router>
    );
  }
}

export default App;