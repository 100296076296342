import React from 'react';
import { Row } from 'react-bootstrap';
import './index.css';
import './App.css';

let video1 = require('./vkv2.mp4');

class CapitolVideo extends React.Component{
    render(){
        return(
            <Row className="videoRow">
                <video className="mainVideo" playsinline="true" webkit-playsInline="true" autoPlay="True" loop muted style={{objectFit: "cover", pointerEvents: "none", transform: 'translateY(25%)', marginBottom: "125px"}} width="100%" height="495px">
          <source src={video1} style={{width: "100%"}}  type="video/mp4"></source>
        </video>
            </Row>
        )
    };

}
export {CapitolVideo};