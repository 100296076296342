import React from 'react';
import './index.css'


class Slogan extends React.Component{
    render(){
        return(
<div className='foldDot' style={{marginTop: "10px"}}/>
        )
    };

}
export {Slogan};