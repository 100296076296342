import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    NavLink as Link,
    useRouteMatch,
    useParams, useLocation
  } from "react-router-dom";
import { Navbar, NavbarBrand, NavLink, Nav, Button, Row, Col, ButtonGroup, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Social } from './social';

import { HomePage } from './home';
import { About } from './aboutUs';


let capabilitycard = require("./capcard.pdf");



class Navigation extends React.Component{

    constructor(props) {
        super(props);
      }

      openPDF = () => {
        window.open(capabilitycard);
      }
      
    componentDidMount() {
        window.scrollTo(0, 0);
      }

      resetOverflow = () => {
        document.documentElement.style.overflowY="auto";
        console.log()
      }
    
    render(){
    
        return(
            

            <Navbar className={this.props.data.menuClicked ? 'test1' : ''} variant="dark" expand="lg" style={{backgroundColor: "black", paddingTop: "0px", paddingBottom: "0px"}}>
                <Col>
                <Row lg="3" className={this.props.data.menuClicked ? 'navWindow' : ''}>
                
                
                
                    <Nav className={this.props.data.menuClicked ? 'navStyles' : 'justify-content-left'} style={{alignItems: "left", marginLeft: "50px"}}>
                    <Link className = 'navlinks' onClick={this.resetOverflow} exact to="/" end style={{textUnderlineOffset: "5px"}} ><NavLink href="/" style={{textDecorationColor: "#B01318"}}>Home</NavLink></Link>
                    <Link onClick={this.resetOverflow} to="/about" style={{textUnderlineOffset: "5px"}}><NavLink href="/about" style={{textDecorationColor: "#B01318"}}>About</NavLink></Link>
                    <a href="/" style={{textUnderlineOffset: "5px"}}><NavLink style={{textDecorationColor: "#B01318"}} disabled href="/services">Services</NavLink></a>
                    <a href="/" style={{textUnderlineOffset: "5px"}}><NavLink disabled href="/careers" style={{textDecorationColor: "#B01318"}}>Careers</NavLink></a>
                </Nav>
                
                    </Row>

            <Row className='mblNavContact justify-content-center' style={{display: "none"}}>
                <Row >
                <Col>
            <Button href={"mailto: paris.a.wolf@voidknightllc.com"} style={{lineHeight: "2", height: "45px", color: "white", borderColor: "#B01318", backgroundColor: "#B01318", marginTop: "10px", width: "127.09px"}}>Contact Us</Button>
            <Button onClick={this.openPDF} className="capCard navCapCard" style={{borderColor: "black", marginRight: "81px", height: "50px", backgroundColor: "white", color: "#B01318", marginTop: "20px", width: "152.48px"}}>Capability Card</Button>
            <div style={{marginTop: "20px"}}/>
            <Social />

                </Col>
                </Row>  

                </Row>
            
            

                </Col>
                <Col>
                <Row className="wdscrnContact" style={{marginRight: "50px"}}>
                    <Col className="col-3 ml-auto"  >
                    <div style={{float: "right", marginTop: "12px", width: "200px", marginRight: "25px"}}>
                <Social />
                </div>
                

                </Col>
                <Button href={"mailto: paris.a.wolf@voidknightllc.com"} style={{lineHeight: "2", height: "45px", color: "white", borderColor: "#B01318", backgroundColor: "#B01318"}}>Contact Us</Button>

               </Row>
                </Col>
                
                
                
            </Navbar>


        )
    };
}

export { Navigation }; 