import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Slogan } from './sloganFold.js';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './index.css';

class Slogans extends React.Component{
    render(){
        return(
            <Container fluid  style={{display: "inline-block", height: "100%"}}>
            <Row className="firstFold">
                <Col className='d-flex justify-content-around'>
                    <Fade cascade>
                <div style={{width: "200px", marginTop: "50px"}}>
                    <div style={{display: "inline-flex", lineHeight: "2"}}>
                    <Slogan /><h2>Loyalty</h2>
                    </div>
<p style={{lineHeight: "2", textAlign: "center", minHeight: "192px"}}>The foundation of the trust we develop between all of our customers, employees, and fellow Warfighters. </p>
                    <hr style={{width: "200px", backgroundColor: "#B01318", height: "1px", marginBottom: "50px"}}/>
                </div>
                </Fade>
                </Col>
    
                <Col className='d-flex justify-content-around'>
                    <Fade cascade>
                <div style={{width: "200px", marginTop: "50px"}}>
                    <div style={{display: "inline-flex", lineHeight: "2"}}>
                    <Slogan /><h2>Vigilance</h2>
                    <div style={{lineDecoration: "underline"}}/>
                    </div>
                    <p style={{lineHeight: "2", textAlign: "center", minHeight: "192px"}}>Remaining aware of changing technologic, economic, and strategic demands is crucial to providing the best solutions. </p>
                    <hr style={{width: "200px", backgroundColor: "#B01318", height: "1px", marginBottom: "50px"}}/>
                </div>
                </Fade>
                </Col>

                <Col className='d-flex justify-content-around'>
                    <Fade>
                <div style={{width: "200px", marginTop: "50px"}}>
                    <div style={{display: "inline-flex", lineHeight: "2"}}>
                    <Slogan /><h2>Honesty</h2>
                    <div style={{lineDecoration: "underline"}}/>
                    </div>
                    <p style={{lineHeight: "2", textAlign: "center", minHeight: "192px"}}>The most effective way to avoid confusion and misunderstanding is to keep communication open and transparent. </p>
                    <hr style={{width: "200px", backgroundColor: "#B01318", height: "1px", marginBottom: "50px"}}/>
                
                </div>
                </Fade>
                </Col>

                </Row>
                </Container>
     
        )
    };

}
export {Slogans};