import React from 'react';
import { Navigation } from './NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Slogans } from './firstFold';
import { ChooseUS } from './whyChooseUs';
import { Solutions } from './greatSolutions';
import { Contract } from './discussContract';
import { Reviews } from './clientReview';
import { Footer } from './footer';
import './font.css';
import './App.css';
import { Row, Col, Button } from 'react-bootstrap';
import { CapitolVideo } from './video';
import { Spin as Hamburger } from 'hamburger-react';


let mainLogo = require("./mainLogo.png");
let capabilitycard = require("./capcard.pdf");


class HomePage extends React.Component{

    state = {
        menuClicked: false
      }

      openPDF = () => {
        window.open(capabilitycard);
      }

      handleClick = () => {
        this.setState({menuClicked: !this.state.menuClicked
        }
        )
        if (this.state.menuClicked === false){
            document.documentElement.style.overflowY="hidden";
        } else {
            document.documentElement.style.overflowY="auto";

        }
    }


    componentDidMount() {
        window.addEventListener("scroll", this.resizeHeaderOnScroll);
        
    }
      

      resizeHeaderOnScroll() {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
        shrinkOn = 100,
        headerEl = document.getElementById('logo');
        let btn = document.getElementById("capCard");

        if (distanceY > shrinkOn) {
            headerEl.classList.add("logoShrink");
            headerEl.classList.remove("logoShrank");
        }
            else {
                headerEl.classList.remove("logoShrink");
                headerEl.classList.add("logoShrank");

            }
        }

    

    render(){
        return(
            <div className="font-link">
            <div style={{position: "fixed", width: "100%", backgroundColor: "white", zIndex: "1000"}}>
            <Row>
            <div className="logoWrapper" style={{width: "100%", position: "relative", display: "inline-flex" }}>
            <Hamburger color='black' size={22} direction={"left"}
            onToggle={this.handleClick}
            />

            <a href="/"><img id='logo' src={mainLogo} style={{height: "75px", width: "75px", marginTop: "10px", marginBottom: "10px"}}/></a>
            
            <Col>
            </Col>
            <Col className="align-items-center" style={{display: "inline-flex", justifyContent: "right"}}>
            <Button onClick={this.openPDF} id='capCard' className="capCard" style={{borderColor: "black", marginRight: "81px", height: "50px", backgroundColor: "white", color: "#B01318"}}>Capability Card</Button>
            </Col>
            </div>
            
            </Row>
            
         
        <Navigation data={this.state} />
        </div>
            <Col>
            <CapitolVideo/>
            </Col>
            <Row>
            <Col>
            <Slogans />
            </Col>
            </Row>            
            
            <ChooseUS/>
            
            <Row>
            <Col>
            <Solutions/>
            </Col>
            </Row>
            
            <Row>
            <Col>
            <Contract/>
            </Col>
            </Row>
            
            <Row>
            <Col>
            <Reviews />
            </Col>
            </Row>
            <Row>
                <Col style={{backgroundColor: "black"}}>
            <footer><Footer /></footer>
            </Col>
            </Row>

            </div>
            
            
            
        )
        
    };
}

export {HomePage};